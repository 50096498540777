<template>
  <div class="summary-page">
    <en-table-layout
      :pagination="false"
      :tableData="pageData.data"
      @selection-change="handleSelectionChange"
    >
      <template slot="header">
        <el-tabs v-model="params.coll_type" type="card" @tab-click="tapChange">
          <el-tab-pane
            :key="item.name"
            v-for="item in editableTabs"
            :label="item.title"
            :name="item.name"
          >
            {{ item.content }}
          </el-tab-pane>
        </el-tabs>
      </template>

      <template slot="toolbar">
        <el-form-item :label="params.coll_type === '1' ? '月份' : '年份'" class="col-auto">
          <el-date-picker
            v-model="value2"
            :type="params.coll_type === '1' ? 'month' : 'year'"
            :placeholder="params.coll_type === '1' ? '选择月份' : '选择年份'"
            @change="dateChange"
          ></el-date-picker>
        </el-form-item>

        <div class="col"></div>

        <div class="col-auto">
          <el-button
            type="primary"
            size="small"
            :loading="importLoading"
            @click="downZd()"
          >
            {{ params.coll_type === '1' ? '下载月度账单' : '下载年度账单' }}
          </el-button>
        </div>
      </template>

      <template slot="table-columns">
        <el-table-column label="日期">
          <template slot-scope="scope">{{ scope.row.time }}</template>
        </el-table-column>
        <el-table-column label="收入（元）">
          <template slot-scope="scope">{{ scope.row.income_sign ? scope.row.income_sign : 0 }}</template>
        </el-table-column>
        <el-table-column label="支出（元）">
          <template slot-scope="scope">{{ scope.row.outcome_sign ? scope.row.outcome_sign : 0 }}</template>
        </el-table-column>
        <el-table-column label="账户余额（元）">
          <template slot-scope="scope">￥{{ scope.row.price }}</template>
        </el-table-column>
        <el-table-column label="操作" width="120">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="downZdDetail(scope.row)"
            >
              下载{{ params.coll_type === '1' ? '日' : '月' }}账单
            </el-button>
          </template>
        </el-table-column>
      </template>
    </en-table-layout>
  </div>
</template>

<script>
// import * as API_logistics from "@/api/expressCompany";
import * as API_account from '@/api/account';
import {handleDownload} from '@/utils';
import {Foundation} from '@/../ui-utils';
import EnTableLayout from '../../../ui-components/TableLayout/src/main';

export default {
  components: {EnTableLayout},
  data() {
    return {
      editableTabs: [
        {
          title: '月度日账单汇总',
          name: '1'
        },
        {
          title: '年度月账单汇总',
          name: '2'
        },
      ],
      value2: '',
      types: 'day',
      txShow: false,
      /** 列表loading状态 */
      loading: false,
      importLoading: false,
      idsList: [],
      txInfo: {},
      txRules: {
        a4a4: [{
          required: true,
          message: '请输入礼包名称',
          trigger: 'blur'
        }],
      },
      /** 列表参数 */
      params: {
        coll_type: '1',
        start_time: '',
        end_time: '',
      },

      /** 列表分页数据 */
      pageData: {
        data: [],
        page_size: 20,
      },

      /** 快递列表 */
      logiList: [],
    };
  },
  activated() {
  },
  mounted() {
    if (this.$route.query.types) {
      this.params.coll_type = this.$route.query.types === 'day' ? '1' : '2';
    }

    this.value2 = Foundation.unixToDate(
      new Date().getTime() / 1000,
      'yyyy-MM-dd'
    );

    let bb = Foundation.getnDate(1, this.value2);
    this.params.start_time = bb[0] / 1000;
    this.params.end_time = bb[1] / 1000;
    this.getHuiList();
  },
  methods: {
    dateChange() {
      let bb = Foundation.getnDate(this.params.coll_type, this.value2);
      this.params.start_time = bb[0] / 1000;
      this.params.end_time = bb[1] / 1000;
      this.getHuiList();
    },

    tapChange() {
      // console.log(this.params.coll_type, "this.params.coll_type");
      this.value2 = Foundation.unixToDate(
        new Date().getTime() / 1000,
        'yyyy-MM-dd'
      );
      let bb = Foundation.getnDate(this.params.coll_type, this.value2);
      this.params.start_time = bb[0] / 1000;
      this.params.end_time = bb[1] / 1000;
      this.getHuiList();
    },

    daterangeDateChange(val) {
      this.params.start_time = val ? val[0] / 1000 : '';
      this.params.end_time = val ? val[1] / 1000 : '';
    },

    downZd() {
      this.loading = true;
      let tHeaders = ['日期', '收入', '支出', '账户余额'];
      let filterVals = ['time', 'income', 'outcome', 'price'];

      const time = new Date(this.value2);
      let name = '财务汇总账单';

      if (this.params.coll_type === '1') {
        name = `${time.getFullYear()}年${time.getMonth() + 1}月` + name;
      } else {
        name = `${time.getFullYear()}年` + name;
      }

      handleDownload(
        this.pageData.data,
        tHeaders,
        filterVals,
        name,
      );
      this.loading = false;
      // delete params.page_no
      // delete params.page_size
    },
    downZdDetail(row) {
      // console.log(row, 'row');
      if (row) {
        this.importLoading = true;
        let params = {
          ...this.params,
        };
        params.time = row.time;
        API_account.getAccountFlowList(params).then((response) => {
          this.loading = false;
          let tHeaders = ['日期', '收入', '支出', '账户余额'];
          response.forEach((item) => {
            item.time = Foundation.unixToDate(
              item.create_date,
              'yyyy-MM-dd hh:mm:ss'
            );
            let come = item.price_type === 1
                ? item.revenue_expense_amount
                : item.revenue_expense_cash;
            // console.log(come, 'come');
            if (come > 0) {
              item.income = come;
              item.outcome = 0;
            } else if (come < 0) {
              item.outcome = come;
              item.income = 0;
            } else {
              item.outcome = 0;
              item.income = 0;
            }
            item.price = item.price_type === 1 ? item.balance_account : item.cash_account;
          });
          let filterVals = ['time', 'income', 'outcome', 'price'];
          // console.log(response, 'response');
          const time = new Date(row.time);

          let name = '财务汇总账单';

          if (this.params.coll_type === '1') {
            name = `${time.getFullYear()}年${time.getMonth() + 1}月${time.getDate()}日` + name;
          } else {
            name = `${time.getFullYear()}年${time.getMonth() + 1}月` + name;
          }

          handleDownload(
            response,
            tHeaders,
            filterVals,
            name,
          );
        });
        this.importLoading = false;
      }
    },
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.getHuiList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.getHuiList();
    },

    /** 分页大小发生改变 */
    handlePageSizeChange1(size) {
      this.params.page_size = size;
      this.getHuiList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange1(page) {
      this.params.page_no = page;
      this.getHuiList();
    },

    /** 搜索事件触发 */
    searchEvent(data) {
      this.params = {
        ...this.params,
        keyword: data,
      };
    },
    getHuiList() {
      this.loading = true;
      API_account.getHuiZongList(this.params).then((response) => {
        this.loading = false;
        this.pageData.data = response;
      });
    },
    handleSelectionChange(val) {
      let ids = [];
      val.forEach((item) => {
        ids.push(item.order_id);
      });
      this.idsList = ids;
    },
  },
};
</script>

<style lang="scss">
.summary-page {
  background: #fff;

  .en-table-header {
    padding: 12px;
  }

  .en-table-layout-toolbar {
    padding: 0;
  }
}
</style>
